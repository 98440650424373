import BackgroundSmallX from "assets/background-small-x.svg";
import BackgroundMediumX from "assets/background-medium-x.svg";
import BackgroundBigX from "assets/background-big-x.svg";

import styles from "./HighlightProduct.module.scss";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";
import { Container } from "react-bootstrap";
// @ts-ignore
import FlipCountdown from "@rumess/react-flip-countdown";
import WaitList from "../Waitlist";
import CountDown from "count-down-react";

// @ts-ignore
const CoundownRenderer = ({ days, hours, minutes, seconds }) => {
  return (
    <div className={styles["highlight__countdown"]}>
      <div>
        <div>{days}</div>
        <span>Days</span>
      </div>
      <div>
        <div>{hours}</div>
        <span>Hours</span>
      </div>
      <div>
        <div>{minutes}</div>
        <span>Minutes</span>
      </div>
    </div>
  );
};

const HighlightProduct = () => {
  const date = new Date(2024, 11, 1).toLocaleDateString("en-US");
  console.log(date);

  return (
    <Container className={styles["highlight"]}>
      <ParallaxProvider>
        <div className={styles["highlight__sneaker"]}>
          <div className={styles["highlight__sneaker-title"]}>Coming soon!</div>
          <CountDown date={date} renderer={CoundownRenderer} />
        </div>
        <Parallax className={styles["highlight__parallax--small"]} speed={-6}>
          <BackgroundSmallX />
        </Parallax>
        <Parallax
          easing="easeInOutBack"
          className={styles["highlight__parallax--medium"]}
          speed={6}
        >
          <BackgroundMediumX />
        </Parallax>
        <Parallax
          easing={"easeOutSine"}
          className={styles["highlight__parallax--big"]}
          speed={-12}
        >
          <BackgroundBigX />
        </Parallax>
        <WaitList />
      </ParallaxProvider>
    </Container>
  );
};

export default HighlightProduct;

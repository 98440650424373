import React, { useCallback, useContext, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HighlightProduct from "components/home/HighlightProduct";

import { useRouter } from "next/router";

import Api from "api/apiInstances";
import { GetServerSidePropsContext } from "next";
import {
  ISneakerListPaged,
  ISneaker,
  INotificationTypeEnum,
} from "api/openApi/api";
import { AxiosResponse } from "axios";
import { normalizeQueryParamsToProductFilter } from "utils/Normalizer";
import { AuthContext } from "contexts/AuthContext";
import { toast } from "react-toastify";
import NotificationElement from "components/common/atoms/NotificationElement";
import { v4 as uuidv4 } from "uuid";
import { GeneralContext } from "contexts/GeneralContext";

export default function Home() {
  const router = useRouter();
  const { updateIsAuthModalOpen, isLoggedIn, onLoginOTC } =
    useContext(AuthContext);
  const { t } = useContext(GeneralContext);

  useEffect(() => {
    if (router.asPath.includes("callbackUrl") && !isLoggedIn) {
      updateIsAuthModalOpen(true);
    }
  }, [router.asPath, updateIsAuthModalOpen, isLoggedIn]);

  const verifyOTC = useCallback(
    async (otc: string) => {
      const response = await onLoginOTC(otc);
      let notification = null;
      if (response.error) {
        notification = {
          title: t("toasts.tokenErrorTitle"),
          body: t("toasts.tokenErrorBody"),
          type: INotificationTypeEnum.Error,
          notificationId: uuidv4(),
        };
      } else {
        notification = {
          title: t("toasts.welcomeTitle"),
          body: t("toasts.welcomeBody"),
          type: INotificationTypeEnum.Info,
          notificationId: uuidv4(),
        };
      }
      toast(
        <NotificationElement
          key={notification.notificationId}
          notification={notification}
        />
      );
    },
    [onLoginOTC]
  );

  useEffect(() => {
    if (router.query.otc) {
      verifyOTC(router.query.otc as string);
    }
  }, []);

  return (
    <Container className="container--having-banner">
      <Row as="section">
        <Col>
          <HighlightProduct />
        </Col>
      </Row>
    </Container>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  try {
    const productFilter = normalizeQueryParamsToProductFilter(context.query);

    const sneakers: AxiosResponse<ISneakerListPaged> =
      await Api.productsApiInstance.getSneakerProducts(productFilter);

    const sneakerOfTheDay: AxiosResponse<ISneaker> =
      await Api.sneakerApiInstance.getSneakerOfTheDay();

    return {
      props: {
        sneakers: sneakers.data,
        sneakerOfTheDay: sneakerOfTheDay.data,
        error: "",
        queryParams: productFilter,
      },
    };
  } catch (e) {
    return {
      props: {
        sneakers: { list: [] },
        queryParams: {},
        sneakerOfTheDay: {},
        error: "Something went wrong",
      },
    };
  }
}
